import $ from 'jQuery'
import slick from 'slick-carousel-browserify';

slick($('.slick_slider'), {
  dots: true,
  speed: 500
});

$( document ).ready(function() {
  $('.mobile_burger').on( "click", function() {
      $('.global_menu__container').toggleClass('show');
    });
});